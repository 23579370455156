import { useState } from 'react';
import { range } from './helpers';

export default function NavTabs(props) {
    const tabMapping = props.tabMapping;

    return (
        <div className='nav_tabs'>
            {
                range(0, tabMapping.length - 1, 1).map( (index) => {
                    return <NavTab
                        key={index}
                        index={index}
                        tabTitle={tabMapping[index].title}
                        onClickTab={() => props.onClickTab(index)}
                    />
                })
            }
        </div>
    )
}

function NavTab({index, tabTitle, onClickTab}) {
    return <button role='tab' id={'tab-' + index} aria-controls={'panel-' + index} aria-selected='false' className='tab-btn' onClick={onClickTab}>{tabTitle}</button>;
}
