export default function AboutMe() {
    const imagesPerSection = 7;

    const petPhotos = [
        'maddy_derp.JPEG',
        'wesley_walk.jpg', 
        'roxy_happy.JPEG',
        'morty_sleep.JPEG',
        'lucy.JPEG',
        'IMG_0300.jpg',
        'IMG_0883.JPEG',
        'maddy_perch.JPEG'
    ];
    
    const travelPhotos = [
        '20171210_080807.jpg',
        '20171210_154123.jpg',
        '20171210_154207.jpg',
        '20171211_120259.jpg',
        '20171211_121728.jpg',
        '20171211_121916.jpg',
        '20171211_123712.jpg',
        '20171213_110819.jpg',
        '20171213_121048.jpg',
        '20171213_140523.jpg',
        '20171213_141020.jpg',
        'IMG_0097.jpg',
        'IMG_0101.jpg',
        'IMG_0111.JPEG',
        'IMG_0118.JPEG',
        'IMG_0122.jpg',
        'IMG_0148.JPEG',
        'IMG_0170.JPEG',
        'IMG_0185.jpg',
        'IMG_0253.jpg',
        'IMG_0324.jpg',
        'IMG_0332.jpg',
        'IMG_0339.jpg',
        'IMG_0340.JPEG',
        'IMG_0341.JPEG',
        'IMG_0404.JPEG',
        'IMG_0439.JPEG',
        'IMG_0457.jpg',
        'IMG_0476.jpg',
        'IMG_0489.JPEG',
        'IMG_0491.jpg',
        'IMG_0492.jpg',
        'IMG_0493.jpg',
        'IMG_0499.jpg',
        'IMG_0502.jpg',
        'IMG_0505.jpg',
        'IMG_0527.jpg',
        'IMG_0549.JPEG',
        'IMG_0551.JPEG',
        'IMG_0553.jpg',
        'IMG_0562.jpg',
        'IMG_0590.jpg',
        'IMG_0594.jpg',
        'IMG_0602.jpg',
        'IMG_0614.jpg',
        'IMG_0633.JPEG',
        'IMG_0657.JPEG',
        'IMG_0683.JPEG',
        'IMG_0714.JPEG',
    ];

    const naturePhotos = [
        'IMG_1546.JPEG',
        'IMG_1557.JPEG',
        'IMG_6338.JPEG',
    ];

    const skills = [
        'Python',
        'SQL',
        'Kafka',
        'React',
        'Javscript',
        'AWS',
        'Docker',
        'Kubernetes',
        'Linux',
        'NoSQL',
    ];

    function getImgs(photos, path) {
        return shuffleArray(photos).map( photoPath => {
            let src = './images/' + path + '/' + photoPath;
            return <a key={photoPath} href={src}>
                <img src={src}></img>
            </a>
        })
    }

    function getTravelImgs() {
        return getImgs(travelPhotos, 'travel');
    }

    function getPetImgs() {
        return getImgs(petPhotos, 'pets');
    }

    function getNatureImgs() {
        return getImgs(naturePhotos, 'nature');
    }

    function shuffleArray(array) {
        let currentIndex = array.length;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          let randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array.slice(0, imagesPerSection);
      }

    return (
        <>
            <div className='tab_container'>
                <div className='about_me'>
                    {/* <a>Click here to go to the pets</a> */}
                    <div className='about_me_item'>
                        <h2>
                            Software Engineer
                        </h2>
                        <div className='swe_descrip_and_skills'>
                            <SWEAboutMeTextPart1/>
                            <figure>
                                <figcaption className='skills_cap'><h3>Skills</h3></figcaption>
                                <div className='swe_skill_list'>
                                    {
                                        skills.map( skill => {
                                            return <div key={skill}>{skill}</div>
                                        })
                                    }
                                </div>
                            </figure>
                        </div>
                        <SWEAboutMeTextPart2/>
                    </div>
                    <div className='about_me_item'>
                        <div className='about_me_large_photo'>
                            <img src='./images/estes_park.jpg' />
                        </div>
                        <h2>
                            Part Time Wedding Officiant
                        </h2>
                        <WeddingOfficiantText></WeddingOfficiantText>
                    </div>
                    <div className='about_me_item'>
                        <div className='about_me_small_photos'>
                            {
                                getTravelImgs()
                            }
                        </div>
                        <h2>
                            Travelling
                        </h2>
                        <TravellingText></TravellingText>
                    </div>
                    <div className='about_me_item'>
                        <h2>
                            Part Time Web Developler
                        </h2>
                        <WebDevText>

                        </WebDevText>
                    </div>
                    <div className='about_me_item'>
                        <div className='about_me_small_photos'>
                            {
                                getNatureImgs()
                            }
                        </div>
                        <h2> 
                            Part Time Florida Man
                        </h2>
                        <FloridaManText>

                        </FloridaManText>
                    </div>
                    <div className='about_me_item'>
                        <div className='about_me_large_photo'>
                            <img src='./images/pets/morty.jpg'/>
                        </div>
                        <div className='about_me_small_photos'>
                            {                                
                                getPetImgs()
                            }   
                        </div>
                        <h2>
                            Cat Dad (And Dog Uncle)
                        </h2>
                        <CatDadText></CatDadText>
                    </div>
                </div>
            </div>
        </>
    )

}


function SWEAboutMeTextPart1() {
    return (
      <>
        <div className="swe_description_brief">
            <p>
                Hello there! I am Christopher, software engineer, part time FloridaMan, and full time cat dad.
            </p>
        </div>
    </>
    )
}

function SWEAboutMeTextPart2() {
    return (
      <>
        <div className="swe_description">
            <p>
                I graduated from UCF with a B.S. in Computer Science in Dec 
                2015 and joined Wayfair in 2016. During my first year in Financial Engineering, 
                I helped build internal tools using React and PHP for our financial stakeholders and for company 
                wide use like the headcount tool, purchase order tool, and payment reconciliation 
                to name a few. 
            </p>
            <p>
                In 2017, I changed changed to a new team with the goal of updating and 
                converting our data processing  platform to current standards and
                bring in newer technologies. Our legacy platform was mostly batched based applications
                built on PHP and SQL and we converted it to a distributed real time processing system 
                using Python, Kafka, and other supporting technologies.
            </p>
            <p>
                This work was a huge success and allowed us to gracefully scale through the following 
                years and even during COVID when our order volume tripled. The refactored legacy 
                applications and datawarehouses were well received by our financial stakeholders.
            </p>
            <p>
                I joined Nike in 2022 as part of their merchandise and catalog teams. We allowed users to synchronize 
                product changes across different campaigns using Python, AWS SNS, Lambdas, and Flask APIs. Unfortunately I was 
                laid off at the end of 2022 and in the mean time, I've been working part time and gig jobs while searching
                for my next role.
            </p>
        </div>   
      </>
    )
  }

function WeddingOfficiantText() {
    return (
        <>
            <div className="wed_off_text">
                <p>
                    I never thought I would have been asked to officiate a wedding let alone travel to the Colorado mountains in winter to do so. 
                </p>
                <p>
                    In 2023, I officiated my sister's wedding in Estes Park, Colorado. It was an incredible opportunity
                    and one of the most meaningful moments in my life, both being asked to officiate and actually doing it.
                </p>
                <p>
                    Since then I have officiated another local Florida wedding and have plans to officiate several other weddings.
                </p>
            </div>   
        </>
    )
}

function FloridaManText() {
    return (
        <>
            <div className="flo_man_text">
                <p>
                    Florida is known for a lot a of silly things and tourism but it's natural features and wildlife 
                    are incredible.
                </p>
                <p>
                    The natural springs are beautiful and a lot of fun to swim in and kayak in. We recently visited Cedar Key and were
                    able to kayak in the open water with dolphins swimming nearby. Florida has a fascinating mix of ecosystems
                    including the wetlands, lagoons, estuaries, and springs.
                </p>
                <p>
                    Yes there are roaming gangs of alligators that you might encounter but as long as you're up 
                    to date on the gator tax, you should be fine.
                </p>
            </div>   
        </>
    )
}

function WebDevText() {
    return (
        <>
            <div className="web_dev_text">
                <p>
                    Hello there! This section might be self explanatory since you're reading my website. While not 
                    traditionally a web developer or designer, I have professional and personal experience using React
                    and other frontend technologies to build websites and UI tools.

                </p>
            </div>   
        </>
    )
}

function TravellingText() {
    return (
        <>
            <div className="travel_text">
                <p>
                    While being laid off is not great, I now had the time to take a trip that I had been planning for. 
                    For three weeks, in 2023 I traveled to Portugal and Spain to walk the 
                    <a href="https://en.wikipedia.org/wiki/Camino_de_Santiago"> Camino De Santiago </a> and it easily 
                    became my favorite trip and travel experience.                     
                </p>
                <p>
                    I spent 12 days walking 180 miles up the coast of Portugal and moved inland
                    before crossing the Minho River into the Galician region of Spain. I met amazing and interesting 
                    people along the way and stayed in beautiful towns that would never be a tourist destination.
                </p>
                <p>
                    Other trips have included going to Iceland in the middle of winter and it was an incredible experience. 
                    We drove the Golden Circle, saw the northern lights, and walked through the ice caves of a glacier.
                </p>
                <p>
                    My current travel goals are to backpack the Appalachian Trail (or a good chunk of it) and to walk the
                    Camino de Santiago again on the Frances route.
                </p>
            </div>   
        </>
    )
}

function CatDadText() {
    return (
        <>
            <div className="cat_dad_text">
                <p>
                    The top picture is my cat Morty. I was never much of a cat person till I found a stray cat
                    and fell in love with him.
                </p>
                <p>
                    The rest of the pictures are friend's and family's pets that I sometimes watch or care for.
                </p>
            </div>   
        </>
    )
}