import { useState, useEffect } from 'react';


import NavTabs from './nav_tabs.js';
import Game from './game.js';
import AboutMe from './about_me.js';
import ContactLinks from './contact_links.js';
import Projects from './projects.js';

export default function MainPage() {
  const [currentTab, setCurrentTab] = useState(0);
  let content = null;

  const tabMapping = [
    {'title': 'About me', 'hidden': false},
    // {'title': 'Home', 'hidden': false},
    {'title': 'Projects', 'hidden': false},    
    {'title': 'Games', 'hidden': false},
    
  ]

  function onClickTab(i) {
    setCurrentTab(i);
  }

  function getCurrentContent() {
    switch(currentTab) {
      case 0:
        return <AboutMe></AboutMe>;

      case 1:
        return <Projects></Projects>

      case 2:
        return <Projects></Projects>
        return <Game></Game>;
    } 
  }

  return (
    <>
    <div className='page_container'>
        <div className='upper_contact_info'>
          <div className='name_text'><h1>Chistopher Beier</h1></div>
          <ContactLinks></ContactLinks>
        </div>
        <img className='my_pic' src='./images/headshot.jpg'/>
        <div className='tab_background'>        
          <NavTabs tabMapping={tabMapping} onClickTab={onClickTab}></NavTabs>
          {getCurrentContent()}
        </div>
        <div className="bottom_contact_info">
          <div className='creation_text'>
            <p>Created using React, Cloudflare, and an AWS s3 bucket.</p>
            <p>Questions? Email: beiercmdev@gmail.com</p>
          </div>
          <ContactLinks></ContactLinks>
        </div>
      </div>
    </>
    
  )
}
