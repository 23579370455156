
export default function ContactLinks() {

    return (
        <>
        <div className='contact_icons'>
            <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/christopher-beier-64b7101b4/'><i className='fa fa-linkedin'></i></a>
            <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/beiercm' ><i className='fa fa-instagram'></i></a>
            <a target='_blank' rel='noopener noreferrer' href='mailto:beiercmdev@gmail.com'><i className='fa fa-envelope'></i></a>
        </div>
        </>
    )
}